import React from "react";
import './Recipients.css';
import RecipientMaster from '../../components/recipient-master/RecipientMaster';

export default class Recipients extends React.Component{
    render(){
        return (
            <section>
                <h1>Clients</h1>
                <RecipientMaster user={this.props.user}/>
            </section>
        );
    }
}