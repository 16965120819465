import React from "react";
import "./Footer.css";

export default class Footer extends React.Component {
    render() {
        return (
            <footer className="Footer">
                <p>
                    Prisme Assurances - 2022 - Relances et mailings
                </p>
                <p>
                    <small>v. 20220725-1915</small>
                </p>
            </footer>
        );
    }
}