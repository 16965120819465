import React from "react";
import './Campaign.css';
import CampaignDetails from '../../components/campaign-details/CampaignDetails';

export default class Campaign extends React.Component{
    render(){
        return (
            <CampaignDetails user={this.props.user}/>
        );
    }
}