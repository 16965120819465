import React from "react";
import './CampaignPreview.css';
import { Link } from "react-router-dom";
import DateConverter from '../../utils/DateConverter';
import CampaignStatus from "../campaign-status/CampaignStatus";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearchPlus } from "@fortawesome/free-solid-svg-icons";

import Campaign from "../../models/Campaign";

export default class CampaignPreview extends React.Component {

    render() {
        return (
            <div className="Preview">
                <Link to={"/campaigns/" + this.props.campaign.id}>
                    <div className="row ">

                        <div className="column column-75">

                            <h1>{this.props.campaign.title}</h1>
                            <p><small>Identifiant : {this.props.campaign.id}</small></p>
                            {(this.props.campaign.updated_at) ?
                                <p>Dernière mise à jour : {DateConverter.sqlToJs(this.props.campaign.updated_at).toLocaleDateString()}</p>
                                :
                                <p>Créée le : {DateConverter.sqlToJs(this.props.campaign.created_at).toLocaleDateString()}</p>
                            }
                            <p>Type : {Campaign.get_kind_label(this.props.campaign.kind)}</p>
                            <p>Objet du mail : {this.props.campaign.subject}</p>
                            <p>Sous-titre du mail : {this.props.campaign.subject2}</p>
                        <button className="button button-outline"><FontAwesomeIcon icon={faSearchPlus} size="lg"/></button>
                        </div>
                        <div className="column column-25">

                        <CampaignStatus campaign={this.props.campaign} />
                        </div>

                    </div>


                </Link>
            </div>);
    }
}