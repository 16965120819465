import React from "react";
import './Payments.css';
import PaymentMaster from '../../components/payment-master/PaymentMaster';

export default class Payments extends React.Component{
    render(){
        return (
            <section>
                <h1>Paiements</h1>
                <PaymentMaster user={this.props.user}/>
            </section>
        );
    }
}