import React from "react";
import Campaign from "../../models/Campaign";
import "./CustomerDataDetails.css";

export default class CustomerDataDetails extends React.Component {
    render() {
        return (
            <article className="CustomerDataDetails">
                <div className="row">

                    <div className="column">

                        <h1>Campagne : {this.props.customer_data.title}</h1>
                        <p>Id. de la campagne : {this.props.customer_data.campaign_id}</p>
                        <p>Date de création : {Campaign.format_date(this.props.customer_data.created_at)}</p>
                        <p>Date de mise à jour : {Campaign.format_date(this.props.customer_data.updated_at)}</p>
                        <p>Type de campagne  : {Campaign.get_kind_label(this.props.customer_data.kind)}</p>
                        <p>Statut de la campagne  : {Campaign.get_campaign_status(this.props.customer_data.status)}</p>
                        <p>Expéditeur : {this.props.customer_data.sender}</p>
                        <p>Sujet : {this.props.customer_data.subject}</p>
                        <p>Sous-titre  : {this.props.customer_data.subject2}</p>
                        <p>Montant fixe  : {this.props.customer_data.fixed_amount || 0} €</p>
                    </div>
                    <div className="column">
                        <h1>Données liées au destinataire</h1>
                        <p>
                            Code postal : {this.props.customer_data.zip_code}
                        </p>
                        <p>
                            Ville : {this.props.customer_data.city}
                        </p>
                        <p>
                            Adresse 1 : {this.props.customer_data.address1}
                        </p>
                        <p>
                            Adresse 2 : {this.props.customer_data.address2}
                        </p>
                        <p>
                            Téléphone 1 : {this.props.customer_data.phone1}
                        </p>
                        <p>
                            Téléphone 2 : {this.props.customer_data.phone2}
                        </p>
                        <p>
                            Téléphone portable : {this.props.customer_data.phone2}
                        </p>
                        <p>
                            Numéro de contrat : {this.props.customer_data.police_code}
                        </p>
                        <p>
                            Début contrat : {Campaign.format_date(this.props.customer_data.start_date)}
                        </p>
                        <p>
                            Fin contrat : {Campaign.format_date(this.props.customer_data.end_date)}
                        </p>
                        <p>
                            Montant : {this.props.customer_data.amount} €
                        </p>
                        <p>
                            Landing page : <a target="_blank" rel="noreferrer" href={Campaign.get_landing_page_url(this.props.customer_data.token)}>{Campaign.get_landing_page_url(this.props.customer_data.token)}</a>
                        </p>
                    </div>

                </div>
            </article>);
    }
}