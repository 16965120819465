import React from "react";
import './RecipientPreview.css';
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearchPlus } from "@fortawesome/free-solid-svg-icons";

export default class RecipientPreview extends React.Component {

    render() {
        return (
            <div className="Preview">
                <Link to={"/recipients/" + this.props.recipient.id}>
                    <div className="row ">

                        <div className="column">

                            <h1>{this.props.recipient.fullname()}</h1>
                            <p><small>Identifiant : {this.props.recipient.id}</small></p>
                            <p>Code client : {this.props.recipient.customer_code}</p>
                            <p>Email : {this.props.recipient.email}</p>
                            <button className="button button-outline"><FontAwesomeIcon icon={faSearchPlus} size="lg"/></button>
                        </div>
                    </div>


                </Link>
            </div>);
    }
}