import React from "react";
import './RecipientMaster.css';

import Recipient from '../../models/Recipient';
import RecipientPreview from '../recipient-preview/RecipientPreview';

import axios from "axios";

import Config from '../../config/Config';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";


const api = axios.create({
    baseURL: Config.api_base_url(),
    timeout: 36000,
    headers: { 'X-Custom-Header': 'foobar' }
});

export default class RecipientMaster extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            recipients: [],
            filtered_recipients: [],
            keyword: "",
            current_campaign: new Recipient(null),
        };
    }

    async componentDidMount() {

        if (this.props.user !== undefined) {
            api.defaults.headers.common = {
                Authorization: `Bearer ${this.props.user.token}`,
            };
        } else {
            console.error(this.props.user)
        }

        try {
            await this.refresh_data();
        } catch (error) {
            console.error(error);
        }
    }

    refresh_data = async () => {

        let recipients = [];

        try {
            recipients = await this.get_recipients();

            this.setState({
                recipients: recipients,
                filtered_recipients: recipients
            });

        } catch (error) {
            console.error(error);
        }

    }

    get_recipients = async () => {
        let result;
        let recipients = [];
        try {
            result = await api.get('recipients');
            recipients = result.data.items.map((json) => Recipient.fromJSON(json));

        } catch (error) {
            if (error.response && error.response.status === 404) {
                console.log("Aucun client pour le moment");
            }
            console.error(error);
        }

        return recipients;
    }

    on_change_search = (e) => {

        const keyword = e.target.value.toLowerCase();

        if (keyword !== "" && keyword !== undefined) {
            const recipients = this.state.recipients.filter((item) => item.firstname.toLowerCase().includes(keyword) || item.lastname.toLowerCase().includes(keyword) || item.customer_code.toLowerCase().includes(keyword));

            this.setState(
                {
                    filtered_recipients: recipients,
                    keyword: keyword
                }
            );
        } else {
            this.setState(
                {
                    filtered_recipients: this.state.recipients,
                    keyword: undefined
                }
            );
        }

    }

    on_submit_search = (e) => {
        e.preventDefault();
    }

    on_cancel_search = (e) => {
        e.preventDefault();
        this.setState(
            {
                filtered_recipients: this.state.recipients,
                keyword: undefined
            }
        );
    }

    search_or_cancel_button = () => {
        return (!this.state.keyword) ? <div>
            
            <input className="search" type="submit" value="Rechercher" onClick={(e) => this.on_submit_search(e)} />
            </div> : <div><button className="cancel button button-clear" onClick={(e) => this.on_cancel_search(e)}><FontAwesomeIcon icon={faTimes}/> Annuler</button></div>
    }

    render() {
        return (<div className="row RecipientMaster">

            <div className="column master">

                <form onSubmit={(e) => this.on_submit_search(e)} className="searchbar row row-center">
                    <input type="text" placeholder="Recherche par prénom, nom ou code client" onChange={(e) => this.on_change_search(e)} />
                    {this.search_or_cancel_button()}
                </form>

                <section className="collection">
                    {this.state.filtered_recipients.map((recipient, index) => <article key={index}><RecipientPreview recipient={recipient} /></article>)}
                </section>
            </div>

        </div>);
    }
}