import React from "react";
import './CampaignStatus.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit,faEnvelope, faClock } from "@fortawesome/free-solid-svg-icons";
import Campaign from '../../models/Campaign';

export default class CampaignStatus extends React.Component{
    render(){
        return(
            <div className={'Status row-center status-'+this.props.campaign.status}>             
            <p>Statut : </p>
            {(this.props.campaign.status===0)? <div className="column"><div><FontAwesomeIcon icon={faEdit}/></div><div>{Campaign.get_campaign_status(this.props.campaign.status)}</div></div> :null}
            {(this.props.campaign.status===1)? <div><div><FontAwesomeIcon icon={faClock}/></div><div>{Campaign.get_campaign_status(this.props.campaign.status)}</div></div> :null}
            {(this.props.campaign.status===2)? <div><div><FontAwesomeIcon icon={faEnvelope}/></div><div>{Campaign.get_campaign_status(this.props.campaign.status)}</div></div> :null}

            </div>
        );
    }
}