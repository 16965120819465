import React from "react";
import './PaymentMaster.css';
import axios from "axios";
import Config from '../../config/Config';
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearchPlus } from "@fortawesome/free-solid-svg-icons";

const api = axios.create({
    baseURL: Config.api_base_url(),
    timeout: 36000,
    headers: { 'X-Custom-Header': 'foobar' }
});

export default class PaymentMaster extends React.Component {

    constructor(props) {
        super(props);

        this.state = {};

        this.vads_order_id_ref = React.createRef();
    }

    async componentDidMount() {

        if (this.props.user !== undefined) {
            api.defaults.headers.common = {
                Authorization: `Bearer ${this.props.user.token}`,
            };
        } else {
            console.error(this.props.user);
        }

    }

    on_submit_search_by_vads_order_id = async (e) => {

        e.preventDefault();

        const vads_order_id = this.vads_order_id_ref.current.value.toLowerCase();

        if (vads_order_id.includes("-") && vads_order_id.length >= 5) {

            let result;

            try {
                result = await api.get(`recipients?vads_order_id=${vads_order_id}`);

                const recipient = result.data.item;

                this.setState({
                    recipient: recipient
                });

            } catch (error) {
                console.error(error);
            }


        } else {
            this.setState({
                recipient: undefined
            });
            alert("Format incorrect. L'identifiant de commande doit comporter une série d'au moins 4 chiffres séparés par un tiret (-)");
        }

    }


    render() {
        return (<div className="row PaymentMaster">

            <div className="column master">

                <form onSubmit={(e) => this.on_submit_search_by_vads_order_id(e)} className="searchbar row row-center">
                    <input ref={this.vads_order_id_ref} type="text" placeholder="Recherche d'un client à partir d'un identifiant de commande System Pay (ex : 1234-1)" />
                    <input type="submit" value="Rechercher" />
                </form>

                <section className="collection">
                    {(this.state.recipient) ?

                        <Link to={`/recipients/${this.state.recipient.recipient_id}`}>
                            <article>
                                <h1>{this.state.recipient.firstname+" "+this.state.recipient.lastname}</h1>
                                <p>{this.state.recipient.email}</p>
                                <button className="button button-outline"><FontAwesomeIcon icon={faSearchPlus} size="lg"/></button>

                            </article>
                        </Link>
                        : null
                    }
                </section>
            </div>

        </div>);
    }
}