import React from "react";
import { Link } from "react-router-dom";
import './Dashboard.css';

export default class Dashboard extends React.Component {
    render() {
        return (
            <section className="Dashboard">
                <h2>Bienvenue {this.props.user.fullname()} !</h2>
                <div className="container">

                    <div className="row">
                        <Link to="/campaigns" className="button">Campagnes</Link>
                    </div>
                    <div className="row">
                        <Link to="/recipients" className="button">Clients</Link>
                    </div>
                    <div className="row">
                        <Link to="/campaigns" className="button">Paiements</Link>
                    </div>
                    <div className="row">
                        <Link to="/blacklist" className="button">Blacklist</Link>
                    </div>
                    <div className="row">
                        <Link to="/help" className="button">Aide</Link>
                    </div>
                </div>
            </section>
        );
    }
}