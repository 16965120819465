export default class Campaign {

    /*
    - Relances RC + PJ en option (PDF, case à cocher / calcul du montant à payer, paiement en ligne)
    - Relances RC + PJ (PDF, paiement en ligne),
    - Relances CA (pdf),
    - Relances Révision RC (PDF, paiement en ligne),
    - Relances Multi-risques montant fixe (Paiement en ligne),
    - Relances Multi-risques montant fixe + montant variable (montant fixe + - montant variable = montant à payer, Paiement en ligne),
    - Communication (Mail et/ou Page web et/ou PDF)
    */

    //template_id = Sendgrid / Dynamic Templates / Template
    static RC_PJ_OPTION = {
        value: 1,
        description: "Relances RC + PJ en option",
        template_id: "d-bd703e3ba6bb4873908d7c8889448d87",
        from:"no-reply@mailing.prisme-assurances.net",
        to:"info@hinault-assurances.com"

    };

    static RC_PJ = {
        value: 2,
        description: "Relances  RC + PJ",
        template_id: "d-1b2c60c4faf748de898666972002cfac",
        from:"no-reply@mailing.prisme-assurances.net",
        to:"info@hinault-assurances.com"
    };

    static CA = {
        value: 3,
        description: "Relances CA",
        template_id: "d-ea50e8c5e87346fcab74794a2afac806",
        from:"demandeca@prisme-assurances.com",
        to:"demandeca@prisme-assurances.com"
    };

    static REVISION_RC = {
        value: 4,
        description: "Relances Révision RC paiement d'un montant variable",
        template_id: "d-8ea3edd1be444c54b0042693bbfdd5c6",
        from:"no-reply@mailing.prisme-assurances.net",
        to:"info@hinault-assurances.com"
    };

    static MR_MF = {
        value: 5,
        description: "Relances Multi-risques paiement d'un montant fixe",
        template_id: "d-57b7daa6fa684468935037c5673fe748",
        from:"no-reply@mailing.prisme-assurances.net",
        to:"info@hinault-assurances.com"
    };

    static MR_MF_MV = {
        value: 6,
        description: "Relances Multi-risques paiement d'un montant fixe + montant variable",
        template_id: "d-268da622562948ca965d123c11839dad",
        from:"no-reply@mailing.prisme-assurances.net",
        to:"info@hinault-assurances.com"
    };

    static COM = {
        value: 7,
        description: "Communication",
        template_id: "d-2c42fcfecc09494abac6ed30dfcd5f7d",
        from:"no-reply@mailing.prisme-assurances.net",
        to:"info@hinault-assurances.com"
    };

    static COM_APPORTEURS = {
        value: 8,
        description: "Communication apporteurs",
        template_id: "d-e08666f532394116adb0880db09e8457",
        from:"no-reply@mailing.prisme-assurances.net",
        to:"info@hinault-assurances.com"
    };

    static AXA = {
        value: 9,
        description: "AXA",
        template_id: "d-d50ed4db13244f1d901da5fdc8528982",
        from:"info@hinault-assurances.com",
        to:"info@hinault-assurances.com"
    };

    //templates 10 / 11 used for internal needs only
    
    static AXA_COM = {
        value: 12,
        description: "Communication AXA",
        template_id: "d-d074571bcb19495785bbc3142a5eb863",
        from:"info@hinault-assurances.com",
        to:"info@hinault-assurances.com"
    };

    static REVISION_RC_WITHOUT_PAYMENT = {
        value: 13,
        description: "Relances Révision RC (sans paiement en ligne)",
        template_id: "d-8ea3edd1be444c54b0042693bbfdd5c6",
        from:"no-reply@mailing.prisme-assurances.net",
        to:"info@hinault-assurances.com"
    };

    static KINDS = [Campaign.RC_PJ_OPTION, Campaign.RC_PJ, Campaign.CA, Campaign.REVISION_RC, Campaign.REVISION_RC_WITHOUT_PAYMENT, Campaign.MR_MF, Campaign.MR_MF_MV, Campaign.COM, Campaign.COM_APPORTEURS, Campaign.AXA, Campaign.AXA_COM];

    static STATUS = [
        { 0: "draft" },
        { 1: "saved" },
        { 2: "sent" },
    ];

    static STATUS_LABELS = ['Brouillon', 'A envoyer', 'Envoyée'];

    static SENDERS = ['no-reply@mailing.prisme-assurances.net'];

    static format_date(val = "") {
        try {
            let a_date = val.split("T")[0];
            let an_hour = val.split('T')[1].split(".")[0];
            return a_date.split("-").reverse().join("/") + " " + an_hour;
        } catch (error) {
            return "";
        }

    }

    static get_campaign_description(val) {
        const kind = Campaign.KINDS.filter(kind => kind.value === val)[0];

        if (!kind || kind === undefined) throw new Error(`Can't find campaign with value ${val}`);
        else return kind;
    }

    static get_campaign_status(val) {
        if (val === 0) {
            return Campaign.STATUS_LABELS[0];
        } else if (val === 1) {
            return Campaign.STATUS_LABELS[1];
        } else {
            return Campaign.STATUS_LABELS[2];
        }
    }

    static get_kind_label(the_kind) {
        const kind = Campaign.KINDS.filter(kind => kind.value === the_kind)[0];

        if (!kind || kind === undefined) throw new Error(`Can't find campaign with value ${the_kind}`);
        else return kind.description;
    }

    static get_landing_page_url(token) {
        return `https://mailing.prisme-assurances.net/landing-pages/${token}`;
    }

    constructor(id) {
        this.id = id;
        this.status = 0;//default status is draft
        this.kind = 1;//default kind is 1
        this.title = "";
        this.subject = "";
        this.subject2 = "";
        this.body = "";
        this.fixed_amount = 0;
        this.sender = Campaign.SENDERS[0];
        this.updated_at = null;
        this.created_at = null;
        this.sent_at = null;
        this.recipients_imported = 0;
    }

    static fromJSON(json) {
        const campaign = new Campaign(json.id);
        campaign.title = json.title;
        campaign.subject = json.subject;
        campaign.subject2 = json.subject2;
        campaign.body = json.body;
        campaign.fixed_amount = json.fixed_amount;
        campaign.status = json.status;
        campaign.created_at = json.created_at;
        campaign.updated_at = json.updated_at;
        campaign.sent_at = json.sent_at;
        campaign.sender = Campaign.SENDERS[0];
        campaign.kind = Number(json.kind);
        campaign.recipients_imported = Number(json.recipients_imported);

        return campaign;
    }
}