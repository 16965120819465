import React from "react";
import './Recipient.css';
import RecipientDetails from "../../components/recipient-details/RecipientDetails";

export default class Recipient extends React.Component{
    render(){
        return (
            <RecipientDetails user={this.props.user}/>
        );
    }
}