import React from "react";
import './RecipientDetails.css';
import axios from "axios";
import { withRouter, Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import Config from '../../config/Config';
import Recipient from "../../models/Recipient";
import CustomerDataDetails from "../customer-data-details/CustomerDataDetails";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const api = axios.create({
    baseURL: Config.api_base_url(),
    timeout: 36000,
    headers: { 'X-Custom-Header': 'foobar' }
});

class RecipientDetails extends React.Component {

    constructor(props) {
        super(props);

        this.state = { recipient: null, filtered_customer_data: [], keyword:"" };
    }

    componentDidMount() {
        api.defaults.headers.common = {
            Authorization: `Bearer ${this.props.user.token}`,
        };

        this.refresh_data();
    }

    refresh_data = async () => {
        let recipient;

        try {
            recipient = await this.get_recipient();
            this.setState({ recipient: recipient, filtered_customer_data: recipient.customer_data });
        } catch (error) {
            console.error(error);
        }

    }

    get_recipient = async () => {
        let { id } = this.props.match.params;//get recipient id from url param         

        let recipient;

        try {
            let result = await api.get('/recipients/' + id);
            recipient = new Recipient(id);
            recipient = Recipient.fromJSON(result.data.recipient);
            return recipient;
        } catch (error) {
            console.error(error);
        }

    }

    on_save = () => {
        this.refresh_data();
    }

    on_change_search = (e) => {

        const keyword = e.target.value;

        if (keyword !== "" && keyword !== undefined) {

            const filtered_customer_data = this.state.filtered_customer_data.filter((item) => (item.campaign_id.toString().includes(keyword)||item.title.includes(keyword)||item.subject.includes(keyword)));

            this.setState(
                {
                    filtered_customer_data: filtered_customer_data,
                    keyword: keyword
                }
            );
        } else {
            this.setState(
                {
                    filtered_customer_data: this.state.recipient.customer_data,
                    keyword: undefined
                }
            );
        }

    }

    on_submit_search = (e) => {
        e.preventDefault();
    }

    on_cancel_search = (e) => {
        e.preventDefault();
        this.setState(
            {
                filtered_customer_data: this.state.recipient.customer_data,
                keyword: undefined
            }
        );
    }

    search_or_cancel_button = () => {
        return (!this.state.keyword) ? <div>
            
            <input className="search" type="submit" value="Rechercher" onClick={(e) => this.on_submit_search(e)} />
            </div> : <div><button className="cancel button button-clear" onClick={(e) => this.on_cancel_search(e)}><FontAwesomeIcon icon={faTimes}/> Annuler</button></div>
    }

    render() {
        return (<div>
            <div>
                <FontAwesomeIcon icon={faArrowLeft} />
                <Link className="button button-clear" to="/recipients">Retour à la liste des clients</Link>
            </div>
            {(this.state.recipient) ?
                <div className="column">
                    <div className="row">
                        <div className="column column-75">
                            <h1>Client : {this.state.recipient.fullname()}</h1>
                            <p><small>Identifiant : {this.state.recipient.id}</small></p>
                            <p>Code client : {this.state.recipient.customer_code}</p>
                            <p>Email : {this.state.recipient.email}</p>
                            <form onSubmit={(e) => this.on_submit_search(e)} className="searchbar row row-center">
                                <input placeholder="Recherche de données par identifiant, titre, sujet de campagne ou code de commande" onChange={(e) => this.on_change_search(e)} />
                                {this.search_or_cancel_button()}
                            </form>
                            <h3>Données du client associées à chaque campagne de mailing</h3>
                            <ul>
                                {this.state.filtered_customer_data.map((cd, index) => <CustomerDataDetails key={index} customer_data={cd} />)}
                            </ul>
                        </div>
                    </div>

                </div>
                : null
            }
            <div>
                <FontAwesomeIcon icon={faArrowLeft} />
                <Link className="button button-clear" to="/recipients">Retour à la liste des clients</Link>
            </div>
        </div>);
    }
}

export default withRouter(RecipientDetails);