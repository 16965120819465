export default class Recipient{
    constructor(id=0){
        this.id = id;
        this.firstname = "Jean";
        this.lastname = "Dupont";
        this.customer_code = 1234;
        //this.email = "info@prisme-assurances.com";
        this.email = "alex@focalys.com";
        this.customerdata = {
            uuid:'633cf205-f9a2-48af-8976-487df8f25b8b',
            recipient_id:0,
            campaign_id:0,
            city:'Nancy',
            zip_code:"54000",
            address1:"1 rue de Nancy",
            address2:"Place Stanislas",
            phone1:"0383102030",
            phone2:"0383203040",
            mobile_phone:"0607080910",
            police_code:"12345678-1234",
            start_date:"2021-01-01",
            end_date:"2021-12-31",
            amount:"1234.56",
            token:""
        };
    }

    fullname(){
        return this.firstname+" "+this.lastname;
    }

    static fromJSON(json) {
        const recipient = new Recipient(json.id);

        recipient.firstname = json.firstname;
        recipient.lastname = json.lastname;
        recipient.customer_code = json.customer_code;
        recipient.email = json.email;
        recipient.customer_data = json.customer_data;

        recipient.created_at = json.created_at;
        recipient.updated_at = json.updated_at;
        
        return recipient;
    }
}