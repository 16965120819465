import './App.css';
import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import Nav from './components/nav/Nav';
import Footer from './components/footer/Footer';
import Campaign from './pages/campaign/Campaign';
import Campaigns from './pages/campaigns/Campaigns';
import Recipients from './pages/recipients/Recipients';
import Recipient from './pages/recipient/Recipient';
import Dashboard from './pages/dashboard/Dashboard';
import Signin from './pages/signin/Signin';
import Help from './pages/help/Help';
import Payments from './pages/payments/Payments';
import Blacklist from './pages/blacklist/Blacklist';

import User from './models/User';

export default class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      user: new User()
    };
  }

  signin_or_dashboard = () => {
    return (this.state.user.is_authorized()) ? <Dashboard user={this.state.user} /> : this.signin();
  }

  on_signIn = (user) => {
    this.setState({ user: user });
  }

  on_signOut = () => {
    this.setState({ user: new User() });
  }

  signin = () => {
    return (<Signin user={this.state.user} onSignIn={this.on_signIn} />);
  }

  render() {
    return (
      <div className="container">
        <Router>

          {(this.state.user.is_authorized()) ? <Nav user={this.state.user} onSignOut={this.on_signOut} /> : null}

          <Switch>
            <Route path="/campaigns/:id">
              {this.state.user.is_authorized() ?
                <Campaign user={this.state.user} /> :
                <Signin />
              }
            </Route>
            <Route path="/campaigns">
              {(this.state.user.is_authorized()) ? <Campaigns user={this.state.user} /> : this.signin()}
            </Route>
            <Route path="/payments">
              {(this.state.user.is_authorized()) ? <Payments user={this.state.user} /> : this.signin()}
            </Route>
            <Route path="/recipients/:id">
              {(this.state.user.is_authorized()) ? <Recipient user={this.state.user} /> : this.signin()}
            </Route>
            <Route path="/recipients">
              {(this.state.user.is_authorized()) ? <Recipients user={this.state.user} /> : this.signin()}
            </Route>
            <Route path="/blacklist">
              {(this.state.user.is_authorized()) ? <Blacklist user={this.state.user} /> : this.signin()}
            </Route>
            <Route path="/help">
              {(this.state.user.is_authorized()) ? <Help user={this.state.user} /> : this.signin()}
            </Route>
            <Route path="/">
              {(this.state.user.is_authorized()) ? <Dashboard user={this.state.user} onSignOut={this.on_signOut} /> : this.signin()}
            </Route>
          </Switch>
          <Footer />
        </Router>
      </div>
    );

  }
}
