import React from "react";
import { Link } from "react-router-dom";
import './Nav.css';

export default class Nav extends React.Component {

    on_click = () => {
        this.props.onSignOut();
    }

    render() {
        return (<nav className="Nav menu">

            <div className="row">
                <div className="column">
                    <div className="column">
                        <Link to="/">
                            <img alt="Logo Prisme Assurances" src="/logo-prisme-assurances.png" />
                            <h1>Relances et mailings</h1>
                        </Link>
                    </div>
                </div>
                <div className="column">
                    <div className="clearfix">
                        <div className="float-right">
                            {this.props.user.token ? <button className="button button-outline quit" onClick={() => this.on_click()}>Déconnexion</button> : null}
                        </div>
                        <div className="float-right">
                            <span className="user">
                                {this.props.user.fullname()}
                            </span> est connecté
                            {(this.props.user.gender === "Mme") ? "e" : null}
                        </div>
                    </div>
                </div>
            </div>

            {(this.props.user.is_authorized()) ?
                <div className="row">

                    <ul>
                        <li>
                            <Link className="button button-clear" to="/">Accueil</Link>
                        </li>
                        <li>
                            <Link className="button button-clear" to="/campaigns">Campagnes</Link>
                        </li>
                        <li>
                            <Link className="button button-clear" to="/recipients">Clients</Link>
                        </li>
                        <li>
                            <Link className="button button-clear" to="/payments">Paiements</Link>
                        </li>
                        <li>
                            <Link className="button button-clear" to="/blacklist">Black List</Link>
                        </li>
                        <li>
                            <Link className="button button-clear" to="/help">Aide</Link>
                        </li>
                    </ul>
                </div>
                : null}
        </nav>);
    }
}