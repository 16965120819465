import React from "react";
import './Help.css';
import Campaign from '../../models/Campaign';

export default class Help extends React.Component {
    render() {
        return (
            <div className="container">
                <h1>Aide</h1>

                <h2>Sommaire</h2>
                <ul>
                    <li>
                        <a href="#campaigns">Campagnes</a>
                    </li>
                    <li>
                        <a href="#recipients">Clients</a>
                    </li>
                    <li>
                        <a href="#excel">Fichier Excel à importer</a>
                    </li>
                </ul>

                <h2 id="campaigns">Campagnes</h2>
                <h3>Caractéristiques</h3>
                <p>Une campagne de mailing est caractérisée par :</p>
                <ul>
                    <li><strong>Un statut :</strong><ul>
                        {Campaign.STATUS_LABELS.map((a_status, index) => <li>{a_status},</li>)}
                    </ul></li>
                    <li><strong>Un type :</strong>
                        <ul>
                            {Campaign.KINDS.map((a_kind, index) => <li>{a_kind.description},</li>)}
                        </ul>
                    </li>
                    <li>
                        <strong>Autres attributs : </strong>
                        <ul>
                            <li>Titre (affiché uniquement dans l'interface back office),</li>
                            <li>Objet du mail (affiché dans l'interface de messagerie du destinataire du mail),</li>
                            <li>Sous-titre du mail (affiché dans l'interface de messagerie du destinataire du mail),</li>
                            <li>Une adresse mail d'expéditeur, non modifiable (affichée dans l'interface de messagerie du destinataire du mail),</li>
                            <li>Un montant à fixe à payer par le destinataire (ex : peut-être utilisée par exemple en tant que valeur de l'Option <i>Protection Juridique</i>),</li>
                        </ul>
                    </li>
                </ul>
                <h3>Conditions d'envoi</h3>
                <p>
                    <strong>Une campagne ne peut être envoyée que si :</strong>
                </p>
                    <ul>
                        <li>
                            Elle contient des destinataires,
                        </li>
                        <li>
                            Elle est passée au statut "A envoyer",
                        </li>
                    </ul>
                <h3>
                    Landing Pages
                </h3>
                <p>Une landing page est une page web sur laquelle un destinataire se rend en cliquant sur un lien prévu effet contenu dans le mail envoyée par l'application.</p>
                <p>Certains types de campagnes ne sont pas liés à une landing page (ex : Relance CA, Communication...).</p>
                <p>Une landing page permet de compléter le mailing par des fonctionnalités spéciques :</p>
                <ul>
                    <li>
                        consultations des données détailles du client,
                    </li>
                    <li>
                        lien vers un paiement en ligne,
                    </li>
                    <li>
                        Téléchargement,
                    </li>
                    <li>
                        ...
                    </li>
                </ul>
                <h2 id="recipients">Clients</h2>
                <h3>
                    Caractéristiques importées par l'admin de Prisme Assurances
                </h3>
                <p>Un destinataire est un client de Prisme Assurances. Il est caratérisée par différentes données issues d'un fichier Excel à uploader.</p>
                <p>Certaines données sont fixes (ex : Prénom, nom, mail, numéro de client), certaines données varient à chaque mailing (montant à payer, numéro de contrat...).</p>
                <ul>
                    <li>
                        Prénom,
                    </li>
                    <li>
                        Nom (ou nom d'entreprise),
                    </li>
                    <li>
                        Code client,
                    </li>
                    <li>
                        Email (utilisé pour l'envoi des mails),
                    </li>
                    <li>
                        Adresse 1,
                    </li>
                    <li>
                        Adresse 2,
                    </li>
                    <li>
                        Ville,
                    </li>
                    <li>
                        Code postal,
                    </li>
                    <li>
                        Numéro de téléphone 1,
                    </li>
                    <li>
                        Numéro de téléphone 2,
                    </li>
                    <li>
                        Numéro de téléphone portable,
                    </li>
                    <li>
                        Numéro de contrat,
                    </li>
                    <li>
                        Date de début de contrat,
                    </li>
                    <li>
                        Date de fin de contrat,
                    </li>
                    <li>
                        Montant à payer,
                    </li>
                </ul>

                <h3>
                    Caractéristiques générées par l'application
                </h3>
                <ul>
                    <li>
                        Token (suite de caractères permettant d'identifier le destinataire et de générer une "landing page" content ses données personnelles. Le token est généré par l'application. Il n'est pas contenu dans le fichier Excel importé.),
                    </li>
                </ul>
                <h3>Black list</h3>
                <p>Les mails ne seront pas envoyés à un client dont l'adresse mail a été "black listée" par le système de mailing (ex : boîte mail pleine, adresse erronée, classement en spams / indésirables...</p>
                <h2 id="excel">Fichier Excel à importer</h2>
                <p>Modèle de fichier Excel à utiliser pour l'import de données <a className="button" href="/destinataires.xls" target="_blank">Télécharger</a></p>
            </div>
        );

    }
}