import React from "react";
import './Campaigns.css';
import CampaignMaster from '../../components/campaign-master/CampaignMaster';

export default class Campaigns extends React.Component{
    render(){
        return (
            <section>
                <h1>Campagnes</h1>
                <CampaignMaster user={this.props.user}/>
            </section>
        );
    }
}