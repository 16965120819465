import React from "react";
import './CampaignDetails.css';
import axios from "axios";
import { withRouter, Link } from "react-router-dom";
import CampaignForm from "../campaign-form/CampaignForm";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import Config from '../../config/Config';
import CampaignStatus from "../campaign-status/CampaignStatus";

const api = axios.create({
    baseURL: Config.api_base_url(),
    timeout: 1000,
    headers: { 'X-Custom-Header': 'foobar' }
});

class CampaignDetails extends React.Component {

    constructor(props) {
        super(props);

        this.state = { campaign: null, count:0 };
    }

    componentDidMount() {
        api.defaults.headers.common = {
            Authorization: `Bearer ${this.props.user.token}`,
        };

        this.refresh_data();
    }

    refresh_data = async () => {
        let campaign,count;

        try {
            campaign = await this.get_campaign();
            count = await this.get_recipients_count();
            this.setState({ campaign: campaign, recipients_count:count });
        } catch (error) {
            console.error(error);
        }

    }

    get_campaign = async () => {
        let campaign;
        let { id } = this.props.match.params;//get campaign id from url param         

        try {
            let result = await api.get('/campaigns/' + id);
            campaign = result.data.campaign;
        } catch (error) {
            console.error(error);
        }

        return campaign;
    }

    get_recipients_count = async () => {
        let count;
        let { id } = this.props.match.params;//get campaign id from url param         

        try {
            let result = await api.get('/recipients/count?campaign_id=' + id);
            count = result.data.count;
        } catch (error) {
            console.error(error);
        }

        return count;
    }

    on_save = () => {
        this.refresh_data();
    }

    render() {
        return (<div>
            <div>
                <FontAwesomeIcon icon={faArrowLeft} />
                <Link className="button button-clear" to="/campaigns">Retour à la liste des campagnes</Link>
            </div>
            {(this.state.campaign) ?
                <div className="column">
                    <div className="row">
                        <div className="column column-75">
                            <h1>{this.state.campaign.title}</h1>
                            <p><small>Identifiant : {this.state.campaign.id}</small></p>
                        </div>
                        <div>
                            <CampaignStatus campaign={this.state.campaign} />
                        </div>
                    </div>

                    <CampaignForm user={this.props.user} campaign={this.state.campaign} recipients_count={this.state.recipients_count} onSave={this.on_save} />
                </div>
                : null
            }
            <div>
                <FontAwesomeIcon icon={faArrowLeft} />
                <Link className="button button-clear" to="/campaigns">Retour à la liste des campagnes</Link>
            </div>
        </div>);
    }
}

export default withRouter(CampaignDetails);