import React from "react";
import './Blacklist.css';

export default class Blacklist extends React.Component{
    render(){
        return (
            <section>
                <h1>Black List</h1>
            </section>
        );
    }
}