export default class User{
    constructor(){
        this.id = 0;
        this.token="";
        this.expiration= "";
        this.firstname="";
        this.lastname="";
        this.gender="";
        this.role=0;
        this.email = "";
        this.created_at = "";
        this.updated_at = "";
    }

    fullname(){
        return this.firstname+" "+this.lastname;
    }

    is_authorized(){
        return this.expiration > Math.floor(new Date().getTime()/1000);
    }
}