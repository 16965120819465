import React from "react";
import './CampaignMaster.css';
import Campaign from '../../models/Campaign';
import CampaignPreview from '../campaign-preview/CampaignPreview';
import CampaignForm from '../campaign-form/CampaignForm';
import axios from "axios";
import Config from '../../config/Config';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";

const api = axios.create({
    baseURL: Config.api_base_url(),
    timeout: 36000,
    headers: { 'X-Custom-Header': 'foobar' }
});

export default class CampaignMaster extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            campaigns: [],
            filtered_campaigns: [],
            shipments: [],
            keyword: "",
            current_campaign: new Campaign(null),
        };

        this.sent_at_ref = React.createRef();
        this.status_ref = React.createRef();
        this.kind_ref = React.createRef();
    }

    async componentDidMount() {

        if (this.props.user !== undefined) {
            api.defaults.headers.common = {
                Authorization: `Bearer ${this.props.user.token}`,
            };
        } else {
            console.error(this.props.user)
        }

        try {
            await this.refresh_data();
        } catch (error) {
            console.error(error);
        }
    }

    refresh_data = async () => {

        let campaigns = [];
        let shipments = [];

        try {
            campaigns = await this.get_campaigns();
            shipments = await this.get_shipments();

            this.setState({
                campaigns: campaigns,
                shipments: shipments,
                filtered_campaigns: campaigns
            });

        } catch (error) {
            console.error(error);
        }

    }

    get_campaigns = async () => {
        let result;
        let campaigns = [];
        try {
            result = await api.get('campaigns');
            campaigns = result.data.items.map((json) => Campaign.fromJSON(json));
            return campaigns;
        } catch (error) {
            if (error.response.status === 404) {
                console.log("Aucune campagne pour le moment");
            }
            console.error(error);
        }
    }

    get_shipments = async () => {
        let result;
        let shipments = [];
        try {
            result = await api.get('campaigns/shipments');
            shipments = result.data.items;
            return shipments;
        } catch (error) {
            if (error.response.status === 404) {
                console.log("Aucune date d'envoi pour le moment");
            }
            console.error(error);
        }
    }

    on_change_search = (e) => {

        const keyword = e.target.value.toLowerCase();

        if (keyword !== "" && keyword !== undefined) {
            const campaigns = this.state.campaigns.filter((item) => (item.title.toLowerCase().includes(keyword) || item.subject.toLowerCase().includes(keyword)));

            this.setState(
                {
                    filtered_campaigns: campaigns,
                    keyword: keyword
                }
            );
        } else {
            this.setState(
                {
                    filtered_campaigns: this.state.campaigns,
                    keyword: undefined
                }
            );
        }

    }

    on_submit_search = (e) => {
        e.preventDefault();
    }

    on_cancel_search = (e) => {
        e.preventDefault();
        this.setState(
            {
                filtered_campaigns: this.state.campaigns,
                keyword: undefined
            }
        );
    }

    search_or_cancel_button = () => {
        return (!this.state.keyword) ? <div>

            <input className="search" type="submit" value="Rechercher" onClick={(e) => this.on_submit_search(e)} />
        </div> : <div><button className="cancel button button-clear" onClick={(e) => this.on_cancel_search(e)}><FontAwesomeIcon icon={faTimes} /> Annuler</button></div>
    }

    on_add_button_click = () => {
        this.setState({
            is_adding: true,
            current_campaign: new Campaign(null)
        });
    }

    on_edit_button_click = () => {
        this.setState({
            is_adding: false,
        });
    }

    on_close_button_click = () => {

        this.setState({
            is_adding: false,
        });
    }

    on_delete = async (campaign) => {
        this.state.current_campaign = campaign;

        try {
            await api.delete(`campaigns/${this.state.current_campaign.id}`);
            this.setState({ current_campaign: null, is_editing: false, is_adding: false });
        } catch (e) {
            console.error(e);
            alert(`Une erreur s'est produite, la campagne n'a pas été supprimée.`);
        }

        try {
            await this.refresh_data();
        } catch (error) {
            console.error(error);
        }

        this.setState({
            is_adding: false,
            current_campaign: null,
        });
    }

    on_edit = (campaign) => {
        this.setState({
            current_campaign: campaign,
            is_adding: false
        });
    }

    display_add_form = () => {
        if (this.state.is_adding) return 'block';
        else return 'none';
    }

    on_save = () => {
        this.refresh_data();
    }

    on_sent_at_change = () => {
        const sent_at = String(this.sent_at_ref.current.value);

        if (sent_at !== "" && sent_at !== undefined && sent_at !== "all") {
            const campaigns = this.state.campaigns.filter(campaign => campaign.sent_at === sent_at);

            this.setState(
                {
                    filtered_campaigns: campaigns,
                    sent_at: sent_at
                }
            );
        } else {
            this.setState(
                {
                    filtered_campaigns: this.state.campaigns,
                    sent_at: undefined
                }
            );
        }

    }

    on_kind_change = () => {
        let kind = this.kind_ref.current.value;


        if (kind !== "" && kind !== undefined && kind !== "all") {

            kind = Number(kind) + 1;

            const campaigns = this.state.campaigns.filter(campaign => campaign.kind === kind);

            this.setState(
                {
                    filtered_campaigns: campaigns,
                    kind: kind
                }
            );
        } else {
            this.setState(
                {
                    filtered_campaigns: this.state.campaigns,
                    sent_at: undefined
                }
            );
        }

    }

    on_status_change = () => {
        let status = this.status_ref.current.value;


        if (status !== "" && status !== undefined && status !== "all") {

            status = Number(status);

            const campaigns = this.state.campaigns.filter(campaign => campaign.status === status);

            this.setState(
                {
                    filtered_campaigns: campaigns,
                    status: status
                }
            );
        } else {
            this.setState(
                {
                    filtered_campaigns: this.state.campaigns,
                    sent_at: undefined
                }
            );
        }

    }

    render() {
        return (<div className="row CampaignMaster">

            <div className="column master">
                <nav className="master">
                    <div>
                        <button onClick={(e) => this.on_add_button_click(e)} className="add">  <FontAwesomeIcon icon={faPlus} /> Créer une nouvelle campagne</button>
                    </div>
                </nav>

                <form onSubmit={(e) => this.on_submit_search(e)} className="searchbar row row-center">
                    <input type="text" placeholder="Recherche par titre ou objet du mail" onChange={(e) => this.on_change_search(e)} />
                    {this.search_or_cancel_button()}
                </form>

                <div className="row">

                    <div className="column">

                        <div>
                            <p>Filtrer par type de campagne</p>
                            <select ref={this.kind_ref} onChange={() => this.on_kind_change()}>
                                <option value="all">Tous types</option>
                                {Campaign.KINDS.map((value, index) => <option key={index} value={index}>{value.description}</option>)}
                            </select>
                        </div>

                    </div>
                    <div className="column">

                        <div>
                            <p>Filtrer par statut</p>
                            <select ref={this.status_ref} onChange={() => this.on_status_change()}>
                                <option value="all">Tous status</option>
                                {Campaign.STATUS_LABELS.map((value, index) => <option key={index} value={index}>{value}</option>)}
                            </select>
                        </div>

                    </div>
                    <div className="column">

                        {(this.state.shipments) ?
                            <div>
                                <p>Filtrer par date d'envoi</p>
                                <select ref={this.sent_at_ref} onChange={() => this.on_sent_at_change()}>
                                    <option value="all">Toutes dates d'envoi</option>
                                    {this.state.shipments.map((sh, index) => <option key={index} value={sh.sent_at}>{Campaign.format_date(sh.sent_at)}</option>)}
                                </select>
                            </div>
                            : null
                        }
                    </div>
                </div>

                {!this.state.filtered_campaigns ? null :
                    <section className="collection">
                        {this.state.filtered_campaigns.map((campaign, index) => <article key={index}><CampaignPreview campaign={campaign} onEdit={(campaign) => { this.on_edit(campaign) }} onDelete={(campaign) => { this.on_delete(campaign) }} /></article>)}
                    </section>
                }
            </div>
            <div className="add-form" style={{ display: this.display_add_form() }}>
                <div className="column">
                    <button onClick={() => this.on_close_button_click()}>x</button>
                    <CampaignForm user={this.props.user} campaign={this.state.current_campaign} recipients_count={0} onSave={this.on_save} />
                </div>
            </div>


        </div>);
    }
}