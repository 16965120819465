import React from "react";
import './CampaignForm.css';
import Campaign from '../../models/Campaign';
import Recipient from '../../models/Recipient';
import DateConverter from '../../utils/DateConverter';
import Form from '../../utils/Form';

import axios from "axios";
import Config from '../../config/Config';

import { withRouter } from "react-router";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload, faExclamationTriangle, faCheckCircle, faUsers, faClock } from "@fortawesome/free-solid-svg-icons";

import { Editor } from '@tinymce/tinymce-react';
//TODO replace API KEY with env var


const api = axios.create({
    baseURL: Config.api_base_url(),
    //timeout: 36000,// = 10mn
});

class CampaignForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            mode: Form.ADD,
            recipient: new Recipient(),
            preview: false,
            file: null,
            import: 0,
            rows_number: 0
        };

        this.title_ref = React.createRef();
        this.subject_ref = React.createRef();
        this.subject2_ref = React.createRef();
        this.body_ref = React.createRef();
        this.editor_ref = React.createRef();
        this.fixed_amount_ref = React.createRef();
        this.kind_ref = React.createRef();
        this.uuid_ref = React.createRef();
        this.status_ref = React.createRef();
        this.sender_ref = React.createRef();

        this.recipients_ref = React.createRef();
        this.preview_ref = React.createRef();
    }

    componentDidMount() {

        api.defaults.headers.common = {
            Authorization: `Bearer ${this.props.user.token}`,
        };

        this.setState({
            mode: (this.props.campaign.created_at === null) ? Form.ADD : Form.EDIT,
        });

        this.title_ref.current.value = this.props.campaign.title;
        this.subject_ref.current.value = this.props.campaign.subject;
        this.subject2_ref.current.value = this.props.campaign.subject2;

        if (this.body_ref.current)
            this.body_ref.current.value = this.props.campaign.body;

        if (this.editor_ref.current)
            this.editor_ref.current.value = this.props.campaign.body;

        this.setState({ body: this.props.campaign.body });

        this.fixed_amount_ref.current.value = this.props.campaign.fixed_amount;
        this.kind_ref.current.value = this.props.campaign.kind;
        this.status_ref.current.value = this.props.campaign.status;
        this.sender_ref.current.value = this.props.campaign.sender;

        // if (this.props.campaign.recipients_imported === 1) {
        //     this.setState({ import: 1 });//done
        // } else if (this.props.campaign.recipients_imported === 2) {
        //     this.setState({ import: 2 });//doing
        // } else {
        //     this.setState({ import: 0 });//not done
        // }

    }

    on_send = async (e) => {
        e.preventDefault();

        if (this.status_ref.current.value === 1 && (this.state.import === 0 || this.state.import === null)) {
            alert("Vous devez d'abord importer des destinataires avant de pouvoir passer la campagne au statut \"A envoyer\"");
            return;
        }

        try {
            await api.post(`campaigns/${this.props.campaign.id}/send`);

            this.props.onSave(Form.EDIT);//call back the parent method

            this.reset();

        } catch (e) {
            console.error(e);
            alert(`Une erreur s'est produite, la campagne n'a pas été envoyée`);
        }



    };

    on_submit = async (e) => {
        e.preventDefault();

        if (this.title_ref.current.value === '' || this.subject_ref.current.value === '' || this.subject2_ref.current.value === '' || this.kind_ref.current.value === '' || this.status_ref.current.value === '') {
            alert(`Vous devez remplir l'ensemble des champs du formulaire.`);
        }

        if (this.state.mode === Form.EDIT) {

            if (this.status_ref.current.value === 1 && (this.state.import === 0 || this.state.import === null)) {
                alert("Vous devez d'abord importer des destinataires avant de pouvoir passer la campagne au statut \"A envoyer\"");
                return;
            }

            try {

                await api.put(`campaigns/${this.props.campaign.id}`, {
                    title: this.title_ref.current.value,
                    subject: this.subject_ref.current.value,
                    subject2: this.subject2_ref.current.value,
                    body: this.editor_ref.current.getContent() || '',
                    fixed_amount: this.fixed_amount_ref.current.value || 0,
                    status: this.status_ref.current.value,
                    kind: this.kind_ref.current.value,
                    sender: this.sender_ref.current.value,
                });

            } catch (e) {
                console.error(e);
                alert(`Une erreur s'est produite, la campagne n'a pas été enregistrée`);
            }

            this.props.onSave(Form.EDIT);//call back the parent method

            this.reset();


        } else if (this.state.mode === Form.ADD) {

            try {

                await api.post("campaigns", {
                    title: this.title_ref.current.value,
                    subject: this.subject_ref.current.value,
                    subject2: this.subject2_ref.current.value,
                    //body: this.body_ref.current.value,
                    body:this.editor_ref.current.getContent() || '',
                    fixed_amount: this.fixed_amount_ref.current.value,
                    status: 0,
                    kind: this.kind_ref.current.value,
                    sender: this.sender_ref.current.value,
                });

            } catch (e) {
                console.error(e);
                alert(`Une erreur s'est produite, la campagne n'a pas été enregistrée`);
            }

            this.props.onSave(Form.ADD);//call back the parent method

            this.reset();

        } else {
            console.error('Le mode du formulaire est mal configuré')
            alert(`Une erreur s'est produite, la campagne n'a pas été enregistrée`);
        }

    }

    on_upload_submit = async (e) => {
        e.preventDefault();
    }

    reset = () => {
        this.title_ref.current.value = "";
        this.subject_ref.current.value = "";
        this.subject2_ref.current.value = "";

        if (this.editor_ref.current) {
            this.editor_ref.current.setContent('');
        }

        this.fixed_amount_ref.current.value = 0;
        this.status_ref.current.value = "";
        this.kind_ref.current.value = 1;
        this.sender_ref.current.value = Campaign.SENDERS[0];
        this.recipients_ref = React.createRef();

        if (this.preview_ref.current !== null)
            this.preview_ref.current.value = "";

        const { history } = this.props;
        history.push('/campaigns');

    }

    on_close_button_click = (e) => {

        e.preventDefault();

        this.setState({
            mode: Form.ADD
        });

        this.reset();

    }

    on_delete_button_click = async (e) => {

        e.preventDefault();

        try {

            await api.delete(`campaigns/${this.props.campaign.id}`);

        } catch (e) {
            console.error(e);
            alert(`Une erreur s'est produite, la campagne n'a pas été supprimée`);
        }

        this.props.onSave(Form.EDIT);//call back the parent method

        this.reset();

    }

    on_archive_button_click = async (e) => {

        e.preventDefault();

        try {

            await api.patch(`campaigns/${this.props.campaign.id}`, { archive: 1 });

        } catch (e) {
            console.error(e);
            alert(`Une erreur s'est produite, la campagne n'a pas été archivée`);
        }

        this.props.onSave(Form.EDIT);//call back the parent method

        this.reset();

    }

    on_toggle_preview = async (e) => {
        this.setState({
            preview: (this.state.preview) ? false : true
        });
    }

    on_send_preview = async (recipient) => {
        try {

            await api.post(`campaigns/${this.props.campaign.id}/send/preview`, {
                id: recipient.id,
                firstname: recipient.firstname,
                lastname: recipient.lastname,
                customer_code: recipient.customer_code,
                email: recipient.email,
                city: recipient.customerdata.city,
                zip_code: recipient.customerdata.zip_code,
                address1: recipient.customerdata.address1,
                address2: recipient.customerdata.address2,
                phone1: recipient.customerdata.phone1,
                phone2: recipient.customerdata.phone2,
                mobile_phone: recipient.customerdata.mobile_phone,
                police_code: recipient.customerdata.police_code,
                start_date: recipient.customerdata.start_date,
                end_date: recipient.customerdata.end_date,
                amount: recipient.customerdata.amount,
                token: recipient.customerdata.token
            });

        } catch (e) {
            console.error(e);
            alert(`Une erreur s'est produite, l'aperçu de la campagne n'a pas été envoyé`);
        }
    }

    on_import = async (e) => {

        if (!this.state.file) {
            alert("Vous devez d'abord sélectionner un fichier");
        }

        try {

            this.setState({ import: 0 });

            const form_data = new FormData();
            form_data.append('file', this.state.file, this.state.file.name);

            const result = await api.post(`recipients/import?campaign_id=${this.props.campaign.id}`, form_data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (result.status === 200) {
                this.setState({ import: 2, rows_number: result.data.rows_number });
            }


            const calling_about_import_status = setInterval(async () => {
                try {
                    const import_result = await api.get(`campaigns/${this.props.campaign.id}`);
                    if (import_result.status === 200) {

                        if (import_result.data.campaign.recipients_imported === 1) {
                            this.setState({ import: 1 });
                            clearInterval(calling_about_import_status);
                        }
                    }
                } catch (error) {
                    console.error(error);
                    alert("Une erreur s'est produite. Les destinataires n'ont pas été importés.");
                }
            }, 10000);

        } catch (error) {
            this.setState({ import: 0 });
            console.error(error);
            alert("Une erreur s'est produite. Les destinataires n'ont pas été importés.");
        }

    }

    on_file_change = async (e) => {
        this.setState({ file: e.target.files[0], import: 0 });
    }

    handleEditorChange = async (e) => {
        if (this.body_ref.current)
            this.body_ref.current.value = this.editor_ref.current.value;
        console.log(e)
    }

    render() {
        return (<main className="CampaignForm">
            <header>
                {(this.state.mode === Form.ADD) && <h3>Nouvelle campagne</h3>}

                {(this.props.campaign.updated_at !== null && this.props.campaign.updated_at !== undefined) &&
                    <p>Dernière mise à jour : {DateConverter.sqlToJs(this.props.campaign.updated_at).toLocaleDateString()}</p>
                }

                {(this.props.campaign.created_at !== null && this.props.campaign.created_at !== undefined) &&
                    <p>Créée le : {DateConverter.sqlToJs(this.props.campaign.created_at).toLocaleDateString()}</p>

                }

                {
                    (this.props.campaign.recipients_imported === 0) && <p className="todo"><FontAwesomeIcon icon={faExclamationTriangle} /> Destinataires à importer.</p>
                }

                {
                    (this.props.recipients_count > 0 && this.state.import === 0) && <p className="success"><FontAwesomeIcon icon={faUsers} /> <strong>{this.props.recipients_count}</strong> destinataires.</p>
                }                
                {
                    (this.state.rows_number > 0 && this.state.import === 1) && <p className="success"><FontAwesomeIcon icon={faUsers} /> <strong>{this.state.rows_number}</strong> destinataires viennent d'être importés.</p>
                }
            </header>
            <div className="row">
                <div className="column">
                    <form onSubmit={(e) => { this.on_submit(e) }}>
                        <fieldset>
                            {(this.props.campaign.status === 0) ?
                                <div>
                                    <label>Type</label>
                                    <select ref={this.kind_ref}>
                                        {Campaign.KINDS.map((kind, index) => <option key={index} value={kind.value}>{kind.description}</option>)}
                                    </select>
                                </div>
                                : <div>
                                    <label>Type</label>
                                    <select disabled ref={this.kind_ref}>
                                        {Campaign.KINDS.map((kind, index) => <option key={index} value={kind.value}>{kind.description}</option>)}
                                    </select>
                                </div>
                            }

                            <div>
                                <label>Titre</label>
                                <p><small>Uniquement utilisé pour la gestion des campagnes, non affiché dans le mail</small></p>
                                <input ref={this.title_ref} placeholder="Titre" type="text" name="title" defaultValue={(this.props.campaign) ? this.props.campaign.title : null} />
                            </div>
                            <div>
                                <label>Objet du mail</label>
                                <p><small>Affiché dans la messagerie du destinataire du mail</small></p>
                                <p><small>Pour les campagnes de type "Demande CA", ne pas indiquer l'année (cette information est calculée et ajoutée automatiquement)</small></p>
                                <input ref={this.subject_ref} placeholder="Objet du mail" type="text" name="subject" defaultValue={(this.props.campaign) ? this.props.campaign.subject : null} />
                            </div>
                            <div>
                                <label>Sous-titre du mail</label>
                                <p><small>Affiché dans la messagerie du destinataire du mail (si compatible)</small></p>
                                <p><small>Pour les campagnes de type "Demande CA", ne pas indiquer l'année (cette information est calculée et ajoutée automatiquement)</small></p>
                                <input ref={this.subject2_ref} placeholder="Sous-titre du mail" type="text" name="subject2" defaultValue={(this.props.campaign) ? this.props.campaign.subject2 : ''} />
                            </div>
                            <div>
                                <label>Contenu du mail</label>
                                <p><small>Uniquement compatible avec les campagnes de mailing de type "Communication"</small></p>
                                <Editor
                                    onInit={(evt, editor) => this.editor_ref.current = editor}
                                    apiKey='67aczz0uh3fc449y2gkcx9tgod6r0xbs9a53gbgsi426rtn4' cloudChannel='dev'
                                    initialValue={this.state.body}
                                    init={{
                                        height: 500,
                                        menubar: false,
                                        plugins: 'link code',
                                        toolbar: 'undo redo | bold italic | alignleft aligncenter alignright | code'
                                    }}
                                />
                            </div>
                            <div>
                                <label>Adresse mail d'expédition</label>
                                <p><small>Non modifiable</small></p>
                                <input ref={this.sender_ref} placeholder="Adresse mail d'expédition" type="email" name="sender" defaultValue={(this.props.campaign) ? this.props.campaign.sender : Campaign.SENDERS[0]} />
                            </div>
                            <div>
                                <label>Montant fixe à payer en € </label>
                                <p><small>(ex : tarif Protection Juridique, laisser à 0 si inutile)</small></p>

                                <input ref={this.fixed_amount_ref} placeholder="Montant Fixe" type="number" name="fixed_amount" defaultValue={(this.props.campaign) ? this.props.campaign.fixed_amount : 0} />
                            </div>
                            {(this.props.campaign.status === 0) ?
                                <div>

                                    <label>Statut</label>
                                    <p> <FontAwesomeIcon icon={faExclamationTriangle} /><em><small> Une campagne doit être associée à des destinataires (via l'import d'un fichier Excel) et avoir le statut "A envoyer" pour pouvoir être envoyée.</small></em></p>
                                    <select ref={this.status_ref}>
                                        <option value="0">{Campaign.STATUS_LABELS[0]}</option>
                                        {
                                            (this.state.mode === Form.EDIT) &&
                                            <option value="1">{Campaign.STATUS_LABELS[1]}</option>
                                        }
                                    </select>


                                </div>
                                : <input ref={this.status_ref} hidden placeholder="Statut" type="number" name="status" defaultValue={(this.props.campaign) && this.props.campaign.status} />}

                            <div className="row">
                                <div className="row">
                                    <Link to="/campaigns" className="cancel button button-clear">Annuler</Link>
                                </div>
                                <div className="row">
                                    {(this.props.campaign.status !== 2 && this.state.mode === Form.EDIT) && <button onClick={(e) => { this.on_delete_button_click(e) }} className="float-right delete button">Supprimer</button>}
                                    {(this.props.campaign.status === 2 && this.state.mode === Form.EDIT) && <button onClick={(e) => { this.on_archive_button_click(e) }} className="float-right archive button">Archiver</button>}
                                </div>
                                {(this.props.campaign.status === 0) &&
                                    <div>
                                        <div className="">
                                            <input className="button button-large" type="submit" value="Enregistrer" />
                                        </div></div>}
                            </div>

                        </fieldset>
                    </form>

                    {(this.props.campaign.status === 1 && this.props.campaign.recipients_imported === 1) &&

                        <div className="container">
                            <div className="row">
                                <div className="column">
                                    <button onClick={(e) => this.on_send(e)} className="button button-large">Envoyer</button>
                                </div>
                            </div>
                        </div>}

                    {(this.props.campaign.status === 0 && this.state.mode === Form.EDIT) &&


                        <form onSubmit={(e) => { this.on_upload_submit(e) }}>
                            <hr />
                            <h3>Destinataires</h3>

                            <div className="row">

                                <div className="column">

                                    {(this.props.campaign.recipients_imported === 0 && this.state.import === 0) &&
                                        <p className="todo"><FontAwesomeIcon icon={faExclamationTriangle} /> Les destinataires doivent être importés.</p>
                                    }


                                    {(this.props.campaign.recipients_imported === 2 || this.state.import === 2) &&
                                        <p className="doing"><FontAwesomeIcon icon={faClock} /> Import en cours...</p>
                                    }


                                    {((this.props.campaign.recipients_imported === 1 && this.state.import === 0) || this.state.import === 1) &&
                                        <div>
                                            <p className="success"><FontAwesomeIcon icon={faCheckCircle} /> Destinataires importés.</p>
                                            <p><FontAwesomeIcon icon={faExclamationTriangle} /> Attention, chaque nouvel import annule et remplace les destinataires déjà importés.</p>
                                        </div>
                                    }

                                </div>


                                <div className="column">
                                    <fieldset>
                                        <div>
                                            <input onChange={(e) => this.on_file_change(e)} ref={this.recipients_ref} placeholder="Destinataires" type="file" name="recipients" defaultValue={(this.props.campaign) ? this.props.campaign.recipients : null} />
                                        </div>
                                        <div className="row row-center">

                                            <div className="column">

                                                {(this.state.import === 1) &&
                                                    <p className="success"><FontAwesomeIcon icon={faCheckCircle} /> Import réussi !</p>
                                                }

                                                {(this.state.import === 2) &&
                                                    <p className="doing"><FontAwesomeIcon icon={faClock} /> Import de <strong>{this.state.rows_number}</strong> destinataires en cours.
                                                    <br/>Veuillez patienter, l'opération peut durer plusieurs minutes...</p>
                                                }

                                                <button onClick={() => this.on_import()} type="submit"><FontAwesomeIcon icon={faUpload} /> Importer le fichier Excel</button>
                                            </div>
                                        </div>
                                    </fieldset>
                                </div>
                            </div>
                        </form>}
                </div>
            </div>
        </main>
        );
    }


}


export default withRouter(CampaignForm);