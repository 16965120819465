import React from "react";
import './Signin.css';
import User from "../../models/User";
import Config from "../../config/Config";

import axios from "axios";


const api = axios.create({
  baseURL: Config.api_base_url(),
  timeout: 1000,
  headers: { 'X-Custom-Header': 'foobar' }
});

export default class Signin extends React.Component {

  constructor(props) {
    super(props);

    this.state = { user: this.props.user };

    this.email_ref = React.createRef();
    this.password_ref = React.createRef();
    this.localstorage_ref = React.createRef();
  }

  on_submit = (e) => {

    e.preventDefault();

    function decode_utf8(s) {
      return decodeURIComponent(escape(s));
    }

    if (this.email_ref.current.value === undefined || this.password_ref.current.value === undefined || this.email_ref.current.value === "" || this.password_ref.current.value === "") {
      alert("Veuillez renseigner votre email et votre mot de passe svp");
      return;
    }

    api({
      method: "post",
      url: "/users/auth",
      auth: { username: this.email_ref.current.value, password: this.password_ref.current.value },
    })
      .then((result) => {
        if (result.status === 200 && result.data.result === "Authorized") {

          const user = new User();

          user.id = result.data.user.id;
          user.firstname = result.data.user.firstname;
          user.lastname = result.data.user.lastname;
          user.gender = result.data.user.gender;
          user.role = result.data.user.role;
          user.created_at = result.data.user.createdAt;
          user.updated_at = result.data.user.updatedAt;

          user.token = result.data.token;

          let payload = JSON.parse(atob(user.token.split(".")[1]));
          user.expiration = decode_utf8(payload["exp"]);

          if (this.localstorage_ref.current.value) {
            localStorage.setItem("token", user.token);
            localStorage.setItem("expiration", user.expiration);
            localStorage.setItem("firstname", user.firstname);
            localStorage.setItem("lastname", user.lastname);
            localStorage.setItem("gender", user.gender);
            localStorage.setItem("email", user.email);
          }

          api.defaults.headers.common = {
            Authorization: `Bearer ${user.token}`,
          };

          this.setState({ user: user });

          this.props.onSignIn(user);

        } else {
          console.error("Erreur de connexion non gérée");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  render() {
    return (
      <div className="row row-center Signin">

        <div className="column">
          <img alt="Logo Prisme Assurances" src="/logo-prisme-assurances.png" />
          <h2>Relances et mailings</h2>
          <form noValidate autoComplete="off" onSubmit={this.on_submit}>
            <fieldset>
              <div>
                <label >Email</label>
                <input
                  ref={this.email_ref}
                  placeholder="Email"
                  type="text"
                  name="email" />
              </div>
              <div>

                <label >Mot de passe</label>
                <input

                  ref={this.password_ref}
                  placeholder="Mot de passe"
                  type="password"
                  name="password" />
              </div>
              <div>
                {/* <label>Se souvenir de moi</label> */}
                <input type="hidden" ref={this.localstorage_ref} />
              </div>
              <div>

                <input type="submit" value="Connexion" />
              </div>
            </fieldset>

          </form>
        </div>
      </div>
    );
  }
}